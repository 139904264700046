body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2f2f2f !important;
  background: #fafdff !important;
}

code {
  font-family: 'Roboto', sans-serif !important;
}

/*button */

.seqrops-btn {
  height: 33px;
  width: 117px;
  font-size: 12px;
}

.seqrops-btn-small {
  line-height: 10px;
  background: #60abfb;
  border: 1px solid #60abfb;
  width: auto;
  color: white;
  border-radius: 3px;
  height: 16px;
  font-size: 12px;
}

.btn-outline {
  background: transparent;
  border: 1px solid #363f5e;
  border-radius: 3px;
  color: #363f5e;

  &:hover {
    background-color: #f9f9f9;
    color: #363f5e;
  }
}

.btn-fill {
  background-color: #60abfb;
  border: 1px solid #60abfb;
  border-radius: 3px;
  color: #fff;

  &:hover {
    background-color: #2e8ff5;
  }
}

.w-custom-btn {
  width: 80px;
}

/*input */
input,
select {
  border-radius: 3px;
  border: 1px solid #cbc8c8;
}

/*scroll*/

::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #ccc;
  /* creates padding around scroll thumb */
}


/*media quary */
@media only screen and (min-width: 819px) and (max-width: 1103px) {
  .view-console-section {
    padding-left: 21% !important;
  }
}

@media screen and (max-width: 1476px) {
  // .side-bar-console-section {
  //   width: 21% !important;
  // }
  // .view-console-section {
  //   padding-left: 21% !important;
  // }
}

@media screen and (max-width: 1321px) {
  .seqrops-side-bar-console-btn-section button {
    font-size: 10px !important;
    padding: 7px !important;
  }

  .seqrops-search-model-section .form-control {
    font-size: 12px !important;
  }

  .seqrops-search-model-section input {
    padding: 8px 3px 8px 3px;
  }

  .seqrops-model-name-section h1 {
    font-size: 16px !important;
    font-weight: 600;
    margin-bottom: 0;
    width: 47vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 988px) {
  .seqrops-property-adding-form-section label {
    font-size: 11px;
  }

  .seqrops-side-bar-console-btn-section button {
    font-size: 7px !important;
  }

  .seqrops-search-model-section .form-control {
    font-size: 10px !important;
  }

  .seqrops-search-model-section input {
    padding: 6px 3px 6px 3px;
  }

  .model-item {
    padding: 5px !important;
  }

  .seqrops-btn {
    height: 30px;
    width: 100px;
    font-size: 11px;
  }

  .seqrops-btn-small {
    width: auto;
    height: 16px;
  }

  .seqrops-navbar-wrapper .seqrops-logo-section img {
    width: 100px !important;
  }
}

.form-select,
.form-control {
  font-size: 14px;
}

.form-control:focus,
.form-select:focus {
  border-color: #6cb4d5 !important;
  box-shadow: none !important;
}

.seqrops-search-model-section {
  .form-control:focus {
    box-shadow: none !important;
  }
}

.form-label {
  font-size: 12px;
  margin-bottom: 0;
  color: #585858;
}

.modal-title {
  font-size: 20px;
}

// textarea {
//   border-color: transparent !important;

//   &:focus,
//   &:focus-visible {
//     border-color: #6cb4d5 !important;
//     box-shadow: none !important;
//     outline: none;
//     border-radius: 6px;
//   }
// }

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  background: white;

}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #ced4da !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
  border-color: #ced4da !important;
}