@import "assets/styles/variables.scss";

.seqrops-dynamic-property-table-section {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 236px);

    table {
        border-collapse: collapse;
        width: 100%;
        border: 0.5px solid rgb(237 230 230);

        tr {
            &:nth-child(even) {
                background-color: #f9f9f9;
            }
        }

        th {
            border: 0.5px solid rgb(237 230 230);
            text-align: left;
            padding: 6px;
            font-size: 13px;
        }

        td {
            text-align: left;
            font-size: 13px;
            border-right: 0.5px solid rgb(237 230 230);
            padding: 6px;
        }

        form td:last-child {
            display: flex;
            justify-content: space-evenly;
        }

        form * {
            font-size: 14px;
        }

        input {
            height: 22px;
            width: 28px;
        }

        button {
            background: none;
            border: none;
        }

        .seqrops-property-table-required-column {
            text-align: left;
        }

        .seqrops-property-table-constraints-column {
            flex-direction: column;

            .title-section {
                display: flex;
                justify-content: flex-end;
                padding: 0px;
                font-size: smaller;
            }

            .value-section {
                display: flex;
                justify-content: center;
                font-weight: bold;
                background-color: $hoverColor;
                font-size: smaller;
            }

            .constraints-data-section {
                margin-left: 0px;
                margin-right: 3px;
                margin-bottom: 10px;
            }
        }
    }
}



.seqrops-property-table-content-section:hover {
    background-color: $propertyTitleDivBg;
}

.seqrops-button-section {
    justify-content: space-evenly;

    border-right: none;
}

.seqrops-property-table-content-section {
    td {
        // text-align: center;
    }

    .seqrops-edit-button {

        width: 1vw;
        height: 2vh;
    }

}

.seqrops-no-data-found-section-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 250px);
}

.seqrops-no-data-found-section {
    text-align: center;

    img {
        width: 200px;
    }

    p {
        padding: 10px;
        color: grey;
        font-size: 12px;
    }
}