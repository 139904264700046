@import "../../assets/styles/variables.scss";
@import "../../assets/styles/_media_queries.scss";

.seqrops-side-bar-console-wrapper {
  background-color: #363f5e;
}

.seqrops-side-bar-console-btn-section {
  display: flex;

  .br-one {
    border-radius: 3px 0px 0px 4px;
  }

  .br-two {
    border-radius: 0px 3px 3px 0px;
  }

  button {
    background-color: $allModelsTab;
    width: 100%;
    padding: 10px;
    color: #f7f7fc;
    position: relative;
    border: none;
    font-size: 12px;
    transition: 1s ease;

    @include max(md) {
      font-size: 11px;
    }
  }

  button:hover {
    background-color: $selectedModelsTab;
  }

  .active {
    background-color: $selectedModelsTab;
  }
}

.console-area-container {
  position: relative;
  display: flex;

  .console-area-main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    transition: 300ms ease-in-out;
    position: relative;
    z-index: 2;

    &--expanded {
      margin-left: 13.8rem;
    }
  }
}

.side-bar-console-section {
  .sidebar {
    width: 235px;
    position: absolute;
    top: 0;
    left: calc(-1 * calc(var(--bs-gutter-x) * 0.5));
    height: calc(100vh - 57px);
    background-color: #363f5e;
    transform: translateX(-200px);
    transition: 300ms ease-in-out;
    z-index: 1;
    cursor: pointer;

    &--expanded {
      background-size: 100%;
      background-position: 0 0;
      transform: translateX(0);
    }

    &:hover {
      background-position: 0 0;
    }
  }

  .sidebar__toggle {
    width: 100%;
    height: 100%;
    height: 4rem;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    background: transparent;
    font-size: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1.8rem;
    padding-bottom: 0;
    transition: 300ms ease;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}

.seqrops-search-model-section {
  display: flex;

  input {
    border-radius: 5px;
    width: 100%;
    background: #e8f7ff;
    font-size: 13px;
    padding: 10px 3px 10px 3px !important;
  }

  .input-group-text {
    background: #e8f7ff;
    border: 0;
  }

  .form-control {
    border: 0;

    &:focus {
      box-shadow: none;
    }
  }
}

.seqrops-model-list-section {
  height: calc(100vh - 186px);
  overflow: auto;
}

.seqrops-empty-search-result-message-section {
  color: white;

  p {
    text-align: center;
    padding: 5px;
  }
}

.collapse-btn {
  position: absolute;
  right: -13px;
  width: 40px !important;
  bottom: 5px;
  background: transparent;
  border: transparent;
  z-index: 99999;

  img {
    width: 30px;
  }
}

.sidbar--collapsed .sidebar_inner {
  display: none;
}

.side-bar-console-section .sidbar--collapsed {
  width: 226px;
  left: calc(-1 * var(--bs-gutter-x) * 0.5);
}

.sidbar--collapsed .collapse-btn {
  transform: rotate(180deg);
}